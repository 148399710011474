.contact_section2 {
  display: flex;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-bottom: 50px;
}

.contact_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  color: #202121;
}

.contact_box1 {
  width: 50%;
}

.contact_box2 {
  width: 50%;
  background: #2f4539;
  padding-bottom: 50px;
}

.contact_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #202121;
  margin-bottom: 14px;
}

.contact_text3 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 20px;
  color: #202121;
}

.form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.contact_label {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #dbb29e;
}

.contact_input {
  width: 90%;
  height: 64px;
  border: 1px solid #dbb29e;
  border-radius: 8px;
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 25px;
  color: #828282;
  background-color: transparent;
}

.contact_textarea {
  width: 90%;
  height: 200px;
  border: 1px solid #dbb29e;
  border-radius: 8px;
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 25px;
  color: #828282;
  background-color: transparent;
  padding-top: 15px;
}

.contact_box {
  margin-bottom: 30px;
}

.contact_but {
  width: 254px;
  height: 64px;
  margin-top: 50px;

  /* Hotel/Hotel Button */

  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #001b1c;
  background: #b18f32;
  border: none;
}

@media (max-width: 500px) {
  .contact_section2 {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-bottom: 50px;
  }

  .contact_box1 {
    width: 100%;
  }

  .contact_box2 {
    width: 100%;
    background: #2f4539;
    padding-bottom: 50px;
  }

  .contact_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #202121;
  }
}
