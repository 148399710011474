.footer {
  background-color: #2f4539;
}
.footer_inner {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;
}

.footer_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  /* Hotel/2 */
  color: #dbb29e;
  margin-bottom: 40px;
}

.footer_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  /* Hotel/2 */

  color: #dbb29e;
  margin-bottom: 40px;
}

.logo_footer {
  margin-bottom: 50px;
}

.footer-up {
  margin-top: 150px;
}

@media (max-width: 500px) {
  .footer_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
  }

  .footer_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* Hotel/2 */
    color: #dbb29e;
    margin-bottom: 40px;
  }

  .footer_text2 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    /* Hotel/2 */

    color: #dbb29e;
    margin-bottom: 40px;
  }

  .footer-up {
    margin-top: 30px;
  }
}
