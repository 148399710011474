.team_section1 {
  background-image: url("../../public/images/team.png");
  height: 90vh;
  background-size: cover;
}

.team_section2 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 200px;
  padding-bottom: 200px;
}

.team_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  color: #dbb29e;
}

.team_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #dbb29e;
}

.team_text3 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  margin-top: 30px;
  width: 80%;

  color: #dbb29e;
}

.teamimg {
  width: 500px;
}

.team_section3 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
}

.team_text4 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  margin-top: 32px;

  /* Hotel/2 */

  color: #dbb29e;
}

.team_text5 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-top: 24px;
  color: #dbb29e;
}

.team_text6 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-top: 16px;
  text-decoration-line: underline;

  /* Hotel/2 */

  color: #dbb29e;
}

.teamimg1 {
  width: 400px;
}

@media (max-width: 500px) {
  .team_section2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .teamimg {
    width: 100%;
  }

  .team_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #dbb29e;
    margin-top: 32px;
  }

  .team_text2 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #dbb29e;
    margin-top: 24px;
  }

  .team_text3 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 30px;
    width: 100%;
    color: #dbb29e;
  }

  .team_section3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .teamimg1 {
    width: 100%;
  }

  .team_section3 div {
    margin-bottom: 30px;
  }
}
