.rooms_section1 {
  background-image: url("../../public/images/rooms1.png");
  height: 55vh;
  background-size: cover;
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  text-align: center;
  padding-top: 20%;
  /* Colour/White */
  color: #ffffff;
}

.rooms_section2 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.rooms_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #dbb29e;
  padding-top: 100px;
}

.rooms_flex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: space-between;
}

.rooms_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  color: #dbb29e;
}

.rooms_text3 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #dbb29e;
  margin-top: 24px;
}

.rooms_text4 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #dbb29e;
  margin-top: 24px;
}

.rooms_hr {
  background: #dbb29e;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 24px;
}

.roomsimg {
  width: 400px;
  height: 600px;
}

.rooms_box {
  margin-bottom: 50px;
}

@media (max-width: 500px) {
  .rooms_section1 {
    font-weight: 400;
    font-size: 32px;
    padding-top: 200px;
  }

  .rooms_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #dbb29e;
    padding-top: 100px;
  }

  .roomsimg {
    width: 100%;
    height: 600px;
  }

  .rooms_text2 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #dbb29e;
  }

  .rooms_text3 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #dbb29e;
    margin-top: 24px;
  }

  .rooms_text4 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #dbb29e;
    margin-top: 24px;
  }
}
