.not_but {
  width: 360px;
  height: 64px;
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #001b1c;
  background: #b18f32;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  border: none;
}

.notimg {
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.nottext {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #001b1c;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 50px;
}

@media (max-width: 500px) {
  .notimg {
    display: block;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .nottext {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #001b1c;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 50px;
  }

  .not_but {
    width: 300px;
    height: 54px;
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #001b1c;
    background: #b18f32;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    border: none;
  }
}
