@font-face {
  font-family: "Trueno";
  src: local("TruenoBd"), url(./fonts/trueno/TruenoBd.otf) format("truetype");
}

@font-face {
  font-family: "TruenoBlk";
  src: local("TruenoBlk"), url(./fonts/trueno/TruenoBlk.otf) format("truetype");
}

@font-face {
  font-family: "TruenoBlkOl";
  src: local("TruenoBlkOl"),
    url(./fonts/trueno/TruenoBlkOl.otf) format("truetype");
}

@font-face {
  font-family: "Wallington Pro";
  src: local("Wallington-Pro"),
    url(./fonts/Wallington-Pro.ttf) format("truetype");
}

a {
  text-decoration: none !important;
}

.map2 {
  height: 70vh;
  width: 100%;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}
