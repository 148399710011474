.policy_section1 {
  background-image: url("../../public/images/bgg1.png");
  height: 50vh;
  background-size: cover;
  margin-top: 100px;
}

.policy_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #ffffff;
  padding: 100px;
  padding-top: 200px;
  padding-bottom: 30px;
}

.policy_text21 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #ffffff;
  padding: 100px;
  padding-top: 100px;
  padding-bottom: 30px;
}

.policy_text11 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  padding-left: 100px;
  width: 70%;
}

.policy_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: #202121;
  padding-top: 100px;
  padding-bottom: 100px;
}

.policy_head {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #202121;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 900;
}

.list {
  padding-bottom: 24px;
}

@media (max-width: 500px) {
  .policy_section1 {
    background-image: url("../../public/images/bgg1.png");
    height: 30vh;
    background-size: cover;
    margin-top: 100px;
  }

  .policy_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #ffffff;
    padding: 100px;
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .policy_text21 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #ffffff;
    padding: 100px;
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .policy_text11 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    padding-left: 100px;
    width: 70%;
  }

  .policy_text2 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: #202121;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .policy_head {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: #202121;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 900;
  }
}
