.header {
  background: #001b1c;
}

.inner_header {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.head_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #dbb29e;
  padding-top: 45px;
}

.head_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #001b1c;
  background: #dbb29e;
  box-sizing: border-box;
  width: 200px;
  text-align: center;
  padding-top: 40px;
}

.header_box {
  display: flex;
  width: 30%;
  justify-content: space-between;
}

.ham_flex {
  display: flex;
  justify-content: space-between;
  width: 25%;
  margin-left: auto;
}

.ham {
  width: 40px;
  cursor: pointer;
}

.animation1 {
  transform: translateX(0);
  transition: transform 1.5s;
}

.animation2 {
  transform: translateX(-100%);
  transition: transform 1.5s;
}

@media (max-width: 500px) {
  .menu-none {
    display: none;
  }

  .ham_flex {
    display: flex;
    justify-content: space-between;
    margin-right: 0px;
  }
}
