.menu {
  height: 90vh;
  background-color: #001b1c;
  padding-top: 48px;
  margin-bottom: 100px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.menu_item {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #dbb29e;
  margin-bottom: 30px;
  text-align: center;
}

@media (max-width: 500px) {
  .menu_item {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #dbb29e;
    margin-bottom: 30px;
    text-align: center;
  }
}
