.room_section1 {
  background-image: url("../../public/images/room.png");
  height: 90vh;
  background-size: cover;
}

.room_section2 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.room_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 76px;
  color: #dbb29e;
  padding-top: 100px;
}

.room_flex1 {
  display: flex;
  justify-content: space-between;
}

.room_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #dbb29e;
  margin-top: 32px;
}

.room_text3 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #dbb29e;
  margin-top: 32px;
}

.room_text4 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  margin-top: 90px;
  color: #dbb29e;
}

.room_text5 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  margin-top: 32px;
  color: #dbb29e;
}

.roomimg {
  display: block;
  width: 500px;
  margin-bottom: 70px;
  margin-left: 100px;
}

.room_text_flex {
  display: flex;
  margin-bottom: 24px;
}

.room_text6 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #dbb29e;
  padding-top: 64px;
  padding-bottom: 30px;
}

.room_box1 {
  background: #2f4539;
  padding-left: 32px;
  margin-top: 48px;
  padding-bottom: 50px;
}

.room_text7 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #dbb29e;
}

.room_text8 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  margin-top: 100px;
  color: #dbb29e;
}

.room_section3 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.room_list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
