.home_section1 {
  background-image: url("../../public/images/bg1.png");
  height: 90vh;
  background-size: cover;
}

.body {
  background-color: #001b1c;
}

.home_section2_inner {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
}
.home_section2_inner1 {
  width: 70%;
  margin-left: auto;
  padding-bottom: 100px;
}

.home_section4_inner {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
}

.home_section4_inner2 {
  width: 100%;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.s41 {
  width: 50%;
  margin-top: 130px;
}

.s42 {
  width: 40%;
  height: 600px;
}

.bbb {
  width: 50%;
}

.img1 {
  width: 40%;
  height: 600px;
  display: block;
}

.himg4 {
  width: 30%;
  height: 500px;
  position: relative;
  margin-left: -100px;
}

.himg5 {
  width: 15%;
  height: 300px;
  position: relative;
  margin-right: -70px;
  margin-top: 150px;
}

.himg51 {
  width: 40%;
  height: 500px;
  margin-left: auto;
  margin-right: 130px;
}

.hdiv {
  width: 70%;
}

.headline_1 {
  color: #dbb29e;
  display: flex;
  width: 60%;
}

.headline_1_text {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
}

.hr {
  width: 300px;
  height: 0.5px;
  margin-top: 30px;
}

.home_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  color: #dbb29e;
  width: 100%;
  margin-top: 44px;
}

.home_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #dbb29e;
  width: 100%;
  margin-top: 24px;
  line-height: 2.2rem;
}

.home_button1 {
  width: 360px;
  height: 96px;
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #001b1c;
  border: none;
  /* Hotel/Hotel Button */
  background: #b18f32;
  margin-top: 32px;
}

.home_section3 {
  display: flex;
  overflow-x: auto;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
}

.home_img1 {
  width: 400px;
}

.home_img2 {
  width: 400px;
}

.home_img3 {
  width: 400px;
}

.home_img4 {
  width: 400px;
}

.home_box {
  margin-right: 30px;
}

.home_text3 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #dbb29e;
  display: flex;
  margin-top: 24px;
}

.text_hr {
  width: 81px;
  height: 0.5px;
  margin: 0px;
  background: #dbb29e;
  border-radius: 100px;
  display: block;
  margin-top: 10px;
  margin-right: 20px;
}

.home_section4 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.home_section4_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
}

.img_section4 {
  width: 400px;
  display: block;
}

.home_section5 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.headline_2 {
  margin-top: 70px;
  color: #dbb29e;
  display: flex;
  width: 70%;
  margin-right: auto;
}

.headline_3 {
  margin-top: 70px;
  color: #dbb29e;
  display: flex;
  width: 45%;
  margin-right: auto;
  margin-left: auto;
}

.home_text4 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #dbb29e;
  text-align: left;
  margin-top: 40px;
}

.home_section5_flex {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  width: 70%;
}

.img8 {
  width: 500px;
}

.home_text5 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 36px;
  color: #dbb29e;
}

.home_section6 {
  background: #2f4539;
}

.home_text6 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #dbb29e;
  width: 70%;
}

.home_section6_inner {
  padding-left: 100px;
  padding-top: 150px;
  padding-bottom: 150px;
}

.section6_button {
  width: 219px;
  height: 96px;
  border: 1px solid #dbb29e;
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #dbb29e;
  margin-top: 50px;
  background: transparent;
}

.home_text7 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #dbb29e;
  text-align: center;
  margin-top: 50px;
}

.social {
  padding-bottom: 100px;
}

@media (max-width: 500px) {
  .home_section2_inner {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0px;
  }
  .home_section4_flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 120px;
  }

  .home_section4_inner {
    width: 100%;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    flex-direction: column;
  }

  .home_section5_flex {
    display: flex;
    flex-direction: column;
    margin-top: 96px;
    justify-content: space-between;
  }

  .img8 {
    width: 100%;
  }

  .img_section4 {
    width: 100%;
    margin-bottom: 30px;
    display: block;
  }

  .img1 {
    width: 100%;
    height: 500px;
    margin-top: 50px;
  }

  .headline_1 {
    margin-top: 50px;
    color: #dbb29e;
    display: flex;
    width: 80%;
  }

  .headline_1_text {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }

  .home_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #dbb29e;
    width: 100%;
    margin-top: 50px;
  }

  .home_text2 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #dbb29e;
    width: 100%;
    margin-top: 24px;
    line-height: 3.5rem;
  }

  .home_section2_inner1 {
    width: 90%;
    margin-left: auto;
    padding-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .home_button1 {
    width: 90%;
    height: 56px;
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #001b1c;
    border: none;
    /* Hotel/Hotel Button */
    background: #b18f32;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  .home_text4 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #dbb29e;
    text-align: left;
    margin-top: 64px;
  }

  .home_text5 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 36px;
    color: #dbb29e;
  }

  .home_section5_flex {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
  }

  .headline_2 {
    margin-top: 50px;
    color: #dbb29e;
    display: flex;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .home_text6 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #dbb29e;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .home_section6_inner {
    padding-left: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section6_button {
    width: 90%;
    height: 56px;
    border: 1px solid #dbb29e;
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #dbb29e;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    background: transparent;
    display: block;
  }

  .home_text7 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #dbb29e;
    text-align: center;
    margin-top: 50px;
  }

  .hr {
    width: 100px;
    margin-top: 10px;
  }

  .himg5 {
    display: none;
  }

  .bbb {
    width: 100%;
  }

  .s41 {
    width: 100%;
    margin-top: 50px;
  }

  .s42 {
    width: 100%;
    height: 600px;
  }

  .himg4 {
    display: none;
  }

  .hdiv {
    width: 100%;
  }

  .himg51 {
    width: 100%;
    height: 500px;
    margin-left: auto;
    margin-right: 0px;
  }

  .home_section4_inner2 {
    width: 100%;
    padding-bottom: 100px;
    margin-top: 0px;
  }

  .headline_3 {
    margin-top: 70px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}
