.gal {
  height: 70vh;
  background-size: cover;
  width: 100%;
}

.gal_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  margin-top: 105px;
  margin-bottom: 50px;
  color: #001b1c;
}

.gal_bar {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #202121;
  margin-top: 100px;
  margin-bottom: 100px;
  justify-content: space-between;
}

.gal_flex {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.galimg {
  width: 400px;
  height: 400px;
  margin-bottom: 40px;
  margin-right: 50px;
}

.active {
  border-bottom: 2px solid #828282;
}

@media (max-width: 500px) {
  .gal_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    color: #001b1c;
  }

  .gal_bar {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    overflow: auto;
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #202121;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: space-between;
  }

  .gal_bar div {
    margin-right: 50px;
    width: 500px;
  }

  .galimg {
    width: 100%;
    height: 400px;
    margin-bottom: 40px;
  }
}
