.bar_section1 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.bar_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  width: 30%;
  color: #dbb29e;
  text-align: center;
  margin-top: 200px;
  margin-left: 12%;
}

.barimg {
  width: 50%;
  display: block;
}

.barimg2 {
  position: relative;
  top: -300px;
}

.bar_section2 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.bar_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  color: #dbb29e;
}

.bar_text3 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #dbb29e;
  margin-top: 24px;
  width: 80%;
}

.bar_section3 {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.bar_section5 {
  background: #2f4539;
  margin-bottom: 100px;
}

.bar_section5_flex {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 300px;
}

.bar_section5_text1 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  color: #dbb29e;
  margin-right: 50px;
}

.bar_section5_text2 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #dbb29e;
}

.bar_section6 {
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}

.b1 {
  position: relative;
  top: -350px;
  width: 45%;
}

.b2 {
  position: relative;
  top: -200px;
  width: 45%;
}

.bar_text4 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  color: #dbb29e;
}

.bar_text5 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  margin-top: 24px;
  color: #dbb29e;
}

.bar_section4_img {
  background-image: url("../../public/images/bar4.png");
  height: 90vh;
  background-size: cover;
  margin-top: 100px;
}

.bar_text6 {
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  padding: 10px;
  border-bottom: 2px solid #dbb29e;
  color: #dbb29e;
  text-align: center;
  margin-bottom: 30px;
}

.bar_box {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding-bottom: 100px;
}

.bar_but {
  width: 360px;
  height: 96px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
  /* Hotel/Hotel Button */
  background: #b18f32;
  margin-top: 100px;
  font-family: "Wallington Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #001b1c;
}

@media (max-width: 500px) {
  .barimg {
    display: block;
    width: 60%;
    margin-left: auto;
  }

  .bar_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    padding-left: 20px;
    width: 70%;
    color: #dbb29e;
    text-align: left;
    padding-bottom: 30px;
    margin-top: 50px;
    margin-left: 0;
  }

  .barimg2 {
    position: relative;
    top: -100px;
    width: 60%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .bar_section1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    justify-content: space-between;
  }

  .bar_text2 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #dbb29e;
    margin-top: 30px;
  }

  .bar_section2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }

  .bar_text3 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #dbb29e;
    margin-top: 24px;
    width: 100%;
    margin-bottom: 30px;
  }

  .bar_text4 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #dbb29e;
  }

  .bar_section3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
  }

  .bar_text5 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 24px;
    color: #dbb29e;
  }

  .bar_but {
    width: 90%;
    height: 56px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    /* Hotel/Hotel Button */
    background: #b18f32;
    margin-top: 100px;
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #001b1c;
  }

  .bar_box {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding-bottom: 100px;
  }

  .bar_text6 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 10px;
    border-bottom: 2px solid #dbb29e;
    color: #dbb29e;
    text-align: center;
    margin-bottom: 30px;
  }

  .bar_section5_flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 300px;
  }

  .bar_section5_text1 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #dbb29e;
    margin-right: 50px;
  }

  .bar_section5_text2 {
    font-family: "Wallington Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #dbb29e;
  }
}
